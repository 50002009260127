<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
    >
      <transition mode="out-in">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-card>
              <b-form @submit.prevent="save">
                <b-row>

                  <!-- Name -->
<!--                  <b-col cols="12">-->
<!--                    <b-form-group-->
<!--                      :label="$t('Name')"-->
<!--                      label-for="v-name"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                        id="v-name"-->
<!--                        v-model="payload.data.name"-->
<!--                        :placeholder="$t('Name')"-->
<!--                        required-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->

<!--                  &lt;!&ndash; Phone &ndash;&gt;-->
<!--                  <b-col cols="12">-->
<!--                    <b-form-group-->
<!--                      :label="$t('Email')"-->
<!--                      label-for="v-seo-name"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                        id="v-email"-->
<!--                        v-model="payload.data.email"-->
<!--                        type="email"-->
<!--                        :placeholder="$t('Email')"-->
<!--                        required-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->

<!--                  &lt;!&ndash; Email &ndash;&gt;-->
<!--                  <b-col cols="12">-->
<!--                    <b-form-group-->
<!--                      :label="$t('Mobile')"-->
<!--                      label-for="v-sort"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                        id="v-mobile"-->
<!--                        v-model="payload.data.mobile"-->
<!--                        type="tel"-->
<!--                        :placeholder="$t('Mobile')"-->
<!--                        required-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->

                  <!-- Commission -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Commission')"
                      label-for="v-sort"
                    >
                      <b-form-input
                        id="v-commotion"
                        v-model="payload.data.commission"
                        :placeholder="$t('Commission')"
                        required
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :label="$t('iban')"
                      label-for="v-sort"
                    >
                      <b-form-input
                        id="v-iban"
                        v-model="payload.data.iban"
                        :placeholder="$t('iban')"
                        required
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Is Active -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Is Active ?')"
                      label-for="v-is-active"
                    >
                      <b-form-checkbox
                        id="v-is-active"
                        v-model="payload.data.is_active"
                        :checked="payload.data.is_active"
                        class="custom-control-primary"
                        name="is_active"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- Submit -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      <template>
                        <span v-if="!payload.isLoading">{{ $t('Save') }}</span>
                        <b-spinner
                          v-else
                          small
                        />
                      </template>
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-card>
              <b-row class="d-flex align-items-center justify-content-between">
                <b-col cols="auto">
                  <div>
                    {{ $t('Balance') }}
                  </div>
                  <h3>
                    <span dir="ltr">{{ this.payload.data.balance }}</span> {{ $t("SAR") }}
                  </h3>
                </b-col>
                <b-col cols="auto">
                  <div class="d-flex align-items-center">
                    <b-form-input
                      id="v-amount"
                      v-model="affiliateWalletPayload.data.credit"
                      class="mr-1"
                      :placeholder="$t('Add Credit')"
                      required
                    />
                    <b-form-input
                      id="v-amount"
                      v-model="affiliateWalletPayload.data.debit"
                      class="mr-1"
                      :placeholder="$t('Add Debit')"
                      required
                    />
                    <b-form-input
                      id="v-amount"
                      v-model="affiliateWalletPayload.data.note"
                      :placeholder="$t('Note')"
                      required
                    />
                    <b-button
                      size="sm"
                      variant="success"
                      class="d-flex ml-1"
                      @click="addAffiliateWallet"
                    >
                      <template>
                        <span v-if="!affiliateWalletPayload.isLoading">
                          <feather-icon icon="PlusSquareIcon" />
                          {{ $t('Add') }}
                        </span>
                        <b-spinner
                          v-else
                          small
                        />
                      </template>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <table class="table b-table table-hover">
                <thead>
                  <th>#{{ $t('Order') }}</th>
                  <th>{{ $t('Debit') }}</th>
                  <th>{{ $t('Credit') }}</th>
                  <th>{{ $t('Date') }}</th>
                  <th>{{ $t('Note') }}</th>
                </thead>
                <tbody>
                  <template v-for="affiliateWallet in affiliateWalletList">
                    <tr :key="affiliateWallet.id">
                      <td>
                        <span v-if="affiliateWallet.order_id">
                          #{{ affiliateWallet.order_id }}
                        </span>
                        <span v-else>
                          /
                        </span>
                      </td>
                      <td>{{ affiliateWallet.debit }} {{ $t("SAR") }}</td>
                      <td>{{ affiliateWallet.credit }} {{ $t("SAR") }}</td>
                      <td>{{ affiliateWallet.created_at }}</td>
                      <td>{{ affiliateWallet.note }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>

            </b-card>
            <pagination
              v-if="affiliateWalletData"
              :limit="3"
              :data="affiliateWalletData"
              @pagination-change-page="getAffiliateWallet"
            />
          </b-col>
        </b-row>
      </transition>
    </b-overlay>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  name: 'AffiliateMarketingEdit',
  directives: {
    Ripple,
  },
  data() {
    return {
      payload: {
        isLoading: false,
        data: {
          name: '',
          email: '',
          mobile: '',
          commission: 0,
          iban: null,
          is_active: true,
        },
      },
      affiliateWalletData: null,
      affiliateWalletPayload: {
        isLoading: false,
        data: {
          credit: 0.00,
          debit: 0.00,
          note: '',
        },
      },
    }
  },
  computed: {
    affiliateWalletList() {
      if (!this.affiliateWalletData) {
        return []
      }

      return this.affiliateWalletData.data
    },
  },
  created() {
    this.getCustomer()
    this.getAffiliateWallet()
  },
  methods: {
    getCustomer() {
      useJwt.get(`/customers/${this.$route.params.id}`, this.payload.data)
        .then(({ data }) => {
          this.payload.data = data.customer
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAffiliateWallet(page = 1) {
      useJwt.get(`/customers/${this.$route.params.id}/affiliate-wallet`, {
        params: { page },
      })
        .then(({ data }) => {
          this.affiliateWalletData = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    addAffiliateWallet() {
      if (!this.affiliateWalletPayload.data.credit && !this.affiliateWalletPayload.data.debit) {
        this.$root.showAlert('error', this.$t('Please add credit or debit value'))
        return;
      }

      this.affiliateWalletPayload.isLoading = true

      useJwt.post(`/customers/${this.$route.params.id}/affiliate-wallet`, this.affiliateWalletPayload.data)
        .then(({ data }) => {
          this.$root.showToast('success', data.message, '')
          this.affiliateWalletPayload.data = {
            credit: 0.00,
            debit: 0.00,
            note: '',
          }
          this.getAffiliateWallet()
          this.getCustomer()
        })
        .catch(({ response }) => {
          let errorMsg = ''

          if (response.status === 422) {
            Object.keys(response.data.errors).forEach(key => {
              errorMsg += `${key}: ${response.data.errors[key].join('| ')}`
            })
          }

          this.$root.showAlert('error', errorMsg)
        }).finally(() => {
          this.affiliateWalletPayload.isLoading = false
        })
    },
    save() {
      this.payload.isLoading = true

      useJwt.put(`/customers/${this.$route.params.id}`, this.payload.data)
        .then(({ data }) => {
          this.$root.showToast('success', data.message, '')
          this.$router.push({ name: 'affiliate-marketing' })
        })
        .catch(({ response }) => {
          let errorMsg = ''

          if (response.status === 422) {
            Object.keys(response.data.errors).forEach(key => {
              errorMsg += `${key}: ${response.data.errors[key].join('| ')}`
            })
          }

          this.$root.showAlert('error', errorMsg)
        }).finally(() => {
          this.payload.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
</style>
